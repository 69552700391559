<template>

  <v-container grid-list-xs text-xs-start v-if="this.objectId !== 'new'">
      <v-layout align-start justify-center row class="mt-6 mx-6" v-if="checkUser">
        <div class="d-flex flex xs12 justify-space-between">

          <v-card class="flex xs12 sm10 md6 mr-6 pa-6">
            <v-form
              ref="emailForm"
              v-model="validEmailForm"
            >
              <v-layout align-start justify-center row>
                <v-flex xs12>
                  <h1>{{ $t('pages.profile.changeEmail') }}</h1>
                  <v-layout align-center justify-center row class="align-self-center">
                  <v-text-field
                    v-model="email"
                    autocomplete="email"
                    append-icon="mdi-email"
                    type="email"
                    :rules="[rules.required, rules.email]"
                    name="input-10-1"
                    :label="$t('auth.emailAddress')"
                  ></v-text-field>
                  <v-btn
                    :disabled="!validEmailForm"
                    v-on:click="validateEmailForm"
                    :loading="loading.indexOf('updateEmail') > -1"
                    class="white--text mb-3"
                    color="primary">{{ $t('crud.save') }}
                  </v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card>
          <v-card class="flex xs12 sm10 md6 mr-6 pa-6">
            <v-form>
              <v-layout align-start justify-center row>
                <v-flex xs12>
                  <h1>{{ $t('pages.profile.resetPassword') }}</h1>
                  <input
                    style="display:none"
                    type="text"
                    name="username"
                    autocomplete="username email"
                    readonly="readonly"
                    :value="currentData && currentData._source && currentData._source.email"/>
                  <!-- To allow proper autocomplete update -->
                  <v-text-field
                    v-model="oldPassword"
                    autocomplete="password"
                    :append-icon="passwordVisible('currentPassword')"
                    :type="passwordVisibleText('currentPassword')"
                    name="input-10-1"
                    :label="$t('auth.currentPassword')"
                    v-on:click:append="togglePasswordVisibility('currentPassword')"
                  ></v-text-field>
                  <v-text-field
                    v-model="newPassword"
                    autocomplete="new-password"
                    :append-icon="passwordVisible('newPassword')"
                    :type="passwordVisibleText('newPassword')"
                    name="input-10-1"
                    :label="$t('auth.newPassword')"
                    v-on:click:append="togglePasswordVisibility('newPassword')"
                  ></v-text-field>
                  <v-text-field
                    v-model="confirmPassword"
                    autocomplete="new-password"
                    :append-icon="passwordVisible('confirmPassword')"
                    :type="passwordVisibleText('confirmPassword')"
                    :rules="[rules.confirmed]"
                    name="input-10-1"
                    :label="$t('auth.confirmPassword')"
                    v-on:click:append="togglePasswordVisibility('confirmPassword')"
                  ></v-text-field>
                  <v-layout align-center justify-end row>
                    <v-btn
                      :disabled="!oldPassword || !newPassword || !confirmPassword"
                      v-on:click="updatePassword"
                      :loading="loading.indexOf('updatePassword') > -1"
                      class="white--text mb-3"
                      color="primary">{{ $t('crud.save') }}
                    </v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card>
          <v-card class="flex xs12 sm10 md6 ml-6 pa-6">
            <v-form>
              <v-layout align-start justify-center row>
                <v-flex xs12>
                  <h1>{{ $t('gdpr.title') }}</h1>
                  <p style="text-align:justify;" v-html="$t('gdpr.text')"></p>
                  <input
                    style="display:none"
                    type="text"
                    name="username"
                    autocomplete="username email"
                    readonly="readonly"
                    :value="currentData && currentData._source && currentData._source.email"/>
                  <v-switch
                    v-model="consent"
                    name="input-10-1"
                    :label="$t(consent ? 'misc.accept' : 'misc.deny')"
                    inset
                  />
                </v-flex>
              </v-layout>
            </v-form>
          </v-card>
        </div>
      </v-layout>
      <v-layout align-start justify-center row class="mt-6 mx-6" v-else>
        <v-flex xs12>
          <v-card class="mb-3 pa-6">
            <v-layout align-start justify-center row>
              <v-flex xs12>
                <h1>{{ $t('pages.profile.adminActions') }}</h1>
              </v-flex>
            </v-layout>
            <v-layout align-end justify-center row>
              <v-flex xs12 sm9>
                <h2>{{ $t('auth.passwordReset.title') }}</h2>
                <span>{{ $t('auth.passwordReset.description') }}</span>
              </v-flex>
              <v-flex xs12 sm3 class="text-right">
                <v-btn
                  type="submit"
                  class="white--text"
                  color="primary"
                  :loading="loading.indexOf('resetPassword') > -1"
                  v-on:click="resetPassword"
                >
                  {{ $t('crud.send') }}
                </v-btn>
              </v-flex>
            </v-layout>
            <v-layout align-end justify-center row>
              <v-flex xs12 sm9>
                <h2>{{ $t('pages.profile.resendInvite') }}</h2>
                <span>{{ $t('pages.profile.resendInviteExplanation') }}</span>
              </v-flex>
              <v-flex xs12 sm3 class="text-right">
                <v-btn
                  type="submit"
                  class="white--text"
                  color="primary"
                  :loading="loading.indexOf('sendInvite') > -1"
                  v-on:click="sendInvite"
                >
                  {{ $t('crud.send') }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
  </v-container>
</template>

<script>
import { Auth } from 'aws-amplify';

import _ from '../../misc/dataAccess';
// import Item from './Item.vue';

export default {
  name: 'Profile',
  components: {
    // Item,
  },
  props: {
    componentData: {
      type: Object,
    },
  },
  data() {
    return {
      currentData: {},
      loading: [],
      passwordVisibility: [],
      validEmailForm: false,
      email: null,
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,
      consent: true,
      rules: {
        required: (value) => !!value || this.$t('misc.required'),
        confirmed: (value) => value === this.newPassword || 'Different password.',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          // Regex find on text-field vuetify
          return pattern.test(value) || this.$t('misc.invalidEmail');
        },
      },
    };
  },
  computed: {
    currentPage() {
      return this.$store.state.settings.currentPage;
    },
    options() {
      return this.currentPage.options[0];
    },
    object() {
      return this.options.object;
    },
    plugin() {
      return this.options.plugin;
    },
    objectId() {
      return (this.componentData && this.componentData.value)
        || this.$route.params.objectId
        || this.$store.state.auth.currentUserffly4u.id;
    },
    checkUser() {
      return this.objectId === this.$store.state.auth.currentUserffly4u.id;
    },
    fields() {
      const fields = {
        write: [],
        read: [],
      };
      if (!this.checkUser) {
        // we are admin, we show the active checkbox
        fields.write.push({
          fid: 'user-active',
          label: 'misc.active',
          description: 'pages.user details.editActive',
          path: '_source.active',
          component: 'switchField',
          default: false,
          value: _.get(this.currentData, '_source.active', false),
          initial: _.get(this.currentData, '_source.active', false),
          edited: false,
        });
      }

      return fields;
    },
  },
  watch: {
    consent(value) {
      const params = {
        GDPR_Consent: value,
      };

      this.$store.dispatch('auth/UPDATE_SELF', params);
    },
  },
  methods: {
    /**
    * Ensure that form is valid before making backend request
    */
    validateEmailForm() {
      this.error = false;
      if (this.$refs.form.validate()) {
        this.updateEmail();
      }
    },
    async updateEmail() {
      // const user = await Auth.currentAuthenticatedUser();
      // this.toggleLoading('updateEmail');
      // const result = await Auth.updateUserAttributes(user, {
      //   'email': this.email,
      // });
      // this.toggleLoading('updateEmail');
      // console.log('changeEmail', result); // SUCCESS
    },
    setCurrentData(data) {
      this.currentData = data;
    },
    async updatePassword() {
      this.toggleLoading('updatePassword');
      this.$nextTick(async () => {
        Auth.currentAuthenticatedUser()
          .then((user) => Auth.changePassword(user, this.oldPassword, this.newPassword))
          .then((data1) => {
            console.log('change request response', data1);
            this.toggleLoading('updatePassword');
            const msg = {
              id: null,
              title: '',
              description: 'Password changed successfully',
              color: 'success',
              timeout: 5000,
            };
            this.$store.commit('snackbars/ADD_MESSAGE', msg);
            this.newPassword = null;
            this.oldPassword = null;
            this.confirmPassword = null;
          })
          .catch((_err) => {
            this.toggleLoading('updatePassword');
            const msg = {
              id: null,
              title: '',
              description: this.$t('auth.passwordReset.error'),
              color: 'error',
              timeout: 5000,
            };
            this.$store.commit('snackbars/ADD_MESSAGE', msg);
          });
      });
    },
    async resetPassword() {
      this.toggleLoading('resetPassword');
      this.$nextTick(async () => {
        await this.$store
          .dispatch('auth/RESET', {
            email: this.currentData._source.email,
          });
        setTimeout(() => {
          // we put a timeout in order for the event to be visible
          this.toggleLoading('resetPassword');
        }, 500);
      });
    },
    async sendInvite() {
      this.toggleLoading('sendInvite');
      this.$nextTick(async () => {
        await this.$store
          .dispatch('auth/INVITE', {
            objectId: this.objectId,
          });
        setTimeout(() => {
          // we put a timeout in order for the event to be visible
          this.toggleLoading('sendInvite');
        }, 500);
      });
    },
    toggleLoading(key) {
      const keyIndex = this.loading.indexOf(key);

      if (keyIndex > -1) {
        this.loading.splice(keyIndex, 1);
      } else {
        this.loading.push(key);
      }
    },
    togglePasswordVisibility(key) {
      const keyIndex = this.passwordVisibility.indexOf(key);

      if (keyIndex > -1) {
        this.passwordVisibility.splice(keyIndex, 1);
      } else {
        this.passwordVisibility.push(key);
      }
    },
    passwordVisible(key) {
      return this.passwordVisibility.indexOf(key) > -1 ? 'visibility' : 'visibility_off';
    },
    passwordVisibleText(key) {
      return this.passwordVisibility.indexOf(key) > -1 ? 'text' : 'password';
    },
  },
  mounted() {
    if (this.$store.state.auth.currentUserffly4u) {
      const source = this.$store.state.auth.currentUserffly4u._source;
      if (Object.prototype.hasOwnProperty.call(source, 'GDPR_Consent')) {
        this.consent = this.$store.state.auth.currentUserffly4u._source.GDPR_Consent;
      }
    }
  },
  metaInfo() {
    return {
      title: this.$t('pages.profile.titleAlt'),
    };
  },
};
</script>
